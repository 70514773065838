import React from "react"
import { Helmet } from "react-helmet"

const OptInSuccessPageHelmet = () => {
    return (
        <Helmet>
            <title>Thank you for subscribing!</title>
        </Helmet>
    )
}

export default OptInSuccessPageHelmet
